<template>
    <a-card title="">
        <div class="funnel-box" style="height:400px;">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <ve-funnel 
            v-if="!loading"
            :tooltip="chartTooltip"
            :data="chartData"
            :extend="{color:['#1cd389', '#668eff', '#ffc751', '#ff6e73', '#8683e6', '#0f4c81']}">
            </ve-funnel>
        </div>
    </a-card>
</template>
<script>
export default {
    name:'funnel',
    props:['filter','is_sub'],
    components:{
    },
    data(){
        return{
            loading:false,
            chartTooltip:{
                formatter: params => {
                let { data, dataIndex } = params;
                return `${data.name}: ${data.realValue}`;
                }   
            },
            chartData: {},
            seach:{
                filter_type:'year'
            }
        }
    },
    created(){
        this.seach.is_sub = this.is_sub
        this.getList()
    },
    methods:{
        async getList() {
            this.loading = true
            let res = await this.$store.dispatch('cWorkbenchFunnelAction', {search:this.seach})
            this.chartData = res.data
            this.loading = false
        },
    },
    watch: {
        filter(newValue) {
            this.seach.filter_type = newValue
            this.getList();
        },
        is_sub(newValue){
            this.seach.is_sub = newValue
            this.getList()
        }
    }
}
</script>
<style lang="scss">
    .funnel-box{
        overflow: hidden;
        svg{
            width: 100%;
            height: 100%;
        }
    }
</style>