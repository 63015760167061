<template>
    <div class="zcxy">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>仪表盘</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="search" style="margin-left:20px">
            <a-form layout='inline'>
                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <a-select :value='is_sub' @change='handleIsSub' style="width: 100px">
                        <a-select-option :value="0">我的</a-select-option>
                        <a-select-option :value="1">我下属的</a-select-option>
                    </a-select>
                    </a-tooltip>
                </a-form-item>
                <a-form-item>
                    <a-select :value="filter_type" @change="handelSelect" style="width: 100px">
                        <a-select-option v-for="(item,index) in typeOptions" :key="index" :value="item.value">
                            {{item.label}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </div>
        <div class="dashboard bk-group" style="padding:20px 20px 0 20px">
            <a-row class="mb16" :gutter="16">
                <a-col :lg="24" :xl="12">
                    <briefing :filter='filter_type' :is_sub='is_sub' />
                </a-col>
                <a-col :lg="24" :xl="12">
                    <target :filter='filter_type' :is_sub='is_sub' />
                </a-col>
                <a-col :lg="24" :xl="12">
                    <!-- <funnel :filter='filter_type' :is_sub='is_sub' /> -->
                    <heatmap :filter='filter_type' :is_sub='is_sub' />
                </a-col>
                <a-col :lg="24" :xl="12">
                    <trend :filter='filter_type' :is_sub='is_sub' />
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
import briefing from './modal/briefing'
import trend from './modal/trend'
import funnel from './modal/funnel'
import target from './modal/target'
import heatmap from './modal/heatmap'
import C_ITEMS from '@/utils/items'
export default {
    name:'workbench',
    data(){
        return{
            filter_type:'year',
            is_sub:0,
            typeOptions:C_ITEMS.typeOptions
        }
    },
    components:{
        briefing,
        trend,
        funnel,
        target,
        heatmap
    },
    created () {
        if(!this.$ls.get('is_sub')){
            this.$ls.set('is_sub', 0)
        }else{
            this.is_sub = this.$ls.get('is_sub')
        }
    },
    methods: {
        handelSelect(val) {
            this.filter_type = val
        },
        handleIsSub(e){
            this.$ls.set('is_sub', e)
            this.is_sub = e
        }
    },
}
</script>