<template>
    <a-card title="">
        <div style="height:400px">
            <div class="title">业绩指标</div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <ve-gauge 
                :data="chartData"
                :settings="chartSettings"
                height="320px"
                :extend="{
                    series:{
                    radius: '80%',
                    center: ['50%', '50%'],
                    min: 0,
                    max: 100,
                    splitNumber: 10,
                    axisLine: {    
                        lineStyle: {
                            width: 8,
                            color: [[0.2, '#f15755'], [0.8, '#5d9cec'], [1, '#00cca2']]
                        }
                    },
                    axisTick: {     
                        length: 15, 
                        lineStyle: {
                            color: 'auto'
                        }
                    },
                    splitLine: {    
                        length: 15, 
                        lineStyle: {
                            color: 'auto'
                        }
                    },
                    axisLabel: {
                        color: '#666',
                    },
                    detail: {
                    formatter:'{value}%',
                    fontSize: 24,
                    offsetCenter: [0, '85%']
                    },
                    title: {
                    fontSize:16,
                    color: '#999',
                    offsetCenter: [0, '55%']
                    },
                    pointer: {
                        width: 5
                    }
                }}"
            ></ve-gauge>
            <div class="targrt">
                <div style="margin-right:15px">
                    <div>目标</div>
                    <div>{{list.achievement_goal}}</div>
                </div>
                <div>
                    <div>合同金额</div>
                    <div>{{list.contract_fee}}</div>
                </div>
            </div>
        </div>
    </a-card>
</template>
<script>
export default {
    name:'target',
    props:['filter','is_sub'],
    data(){
        this.chartSettings = {
            dataType: {
            '占比': 'percent'
            },
            seriesMap: {
            '占比': {
                min: 0,
                max: 1
            }
            }
        }
        return{
            loading:false,
            list:[],
            chartData: {
                columns: ['type', 'value'],
                rows: [
                    { type: '占比', value: 0 }
                ]
            },
            seach:{
                filter_type:'year'
            },
        }
    },
    created(){
        this.seach.is_sub = this.is_sub
        this.getList()
    },
    methods:{
        async getList() {
            this.loading = true
            let res = await this.$store.dispatch('cWorkbenchAchievementAction', {search:this.seach})
            this.list = res.data
            this.chartData.rows[0].value = res.data.achievement_precent
            this.loading = false
        },
    },
    watch: {
        filter(newValue) {
            this.seach.filter_type = newValue
            this.getList();
        },
        is_sub(newValue){
            this.seach.is_sub = newValue
            this.getList()
        }
    },
}
</script>
<style lang="scss" scoped>
    .targrt{
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>