<template>
    <a-card title="">
        <div class="briefing">
            <div class="title">销售简报</div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-row :gutter="16">
                <a-col :span="6">
                    <a-card style="margin-bottom:9px">
                        <a-statistic
                            title="招生线索"
                            align="center"
                            :value="list.leads_count"
                            :value-style="{ color: '#00cca2' }"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>

                 <a-col :span="6">
                    <a-card style="margin-bottom:9px">
                        <a-statistic
                            title="有效客户"
                            align="center"
                            :value="list.customer_count"
                            :value-style="{ color: '#00cca2' }"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>

                <a-col :span="6">
                    <a-card>
                        <a-statistic
                            title="承诺到访(人)"
                            align="center"
                            :value="list.promise_attend_count"
                            :value-style="{ color: '#00cca2' }"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>

                <a-col :span="6">
                    <a-card style="margin-bottom:9px">
                        <a-statistic
                            title="实际到访"
                            align="center"
                            :value="list.actual_attend_count"
                            :value-style="{ color: '#00cca2' }"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>

                 <a-col :span="6">
                    <a-card style="margin-bottom:9px">
                        <a-statistic
                            title="DEMO试听"
                            align="center"
                            :value="list.demo_course_count"
                            :value-style="{ color: '#00cca2' }"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>

                <a-col :span="6">
                    <a-card style="margin-bottom:9px">
                        <a-statistic
                            title="跟进统计"
                            align="center"
                            :value="list.follow_count"
                            :value-style="{ color: '#00cca2' }"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>

                <a-col :span="6">
                    <a-card style="margin-bottom:9px">
                        <a-statistic
                            title="签约合同"
                            align="center"
                            :value="list.contract_count"
                            class="demo-class"
                            :value-style="{ color: '#00cca2' }"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>
                
            </a-row>
        </div>
    </a-card>
</template>
<script>
export default {
    name:'briefing',
    props:['filter','is_sub'],
    data(){
        return{
            loading:false,
            list:[],
            seach:{
                filter_type:'year'
            },
        }
    },
    created(){
        this.seach.is_sub = this.is_sub
        this.getList()
    },
    methods:{
        async getList() {
            this.loading = true
            let res = await this.$store.dispatch('cWorkbenchAction', {search:this.seach})
            this.list = res.data
            this.loading = false
        },
    },
    watch: {
        filter(newValue) {
            this.seach.filter_type = newValue
            this.getList()
        },
        is_sub(newValue){
            this.seach.is_sub = newValue
            this.getList()
        }
    },
}
</script>
<style lang="scss">
    .briefing{
        height: 400px;
        .title{
            margin-bottom: 9px;
        }
        .ant-card-body{
            padding: 10px;
        }
    }
</style>