var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":""}},[_c('div',{staticStyle:{"height":"400px"}},[_c('div',{staticClass:"title"},[_vm._v("业绩指标")]),_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('ve-gauge',{attrs:{"data":_vm.chartData,"settings":_vm.chartSettings,"height":"320px","extend":{
                series:{
                radius: '80%',
                center: ['50%', '50%'],
                min: 0,
                max: 100,
                splitNumber: 10,
                axisLine: {    
                    lineStyle: {
                        width: 8,
                        color: [[0.2, '#f15755'], [0.8, '#5d9cec'], [1, '#00cca2']]
                    }
                },
                axisTick: {     
                    length: 15, 
                    lineStyle: {
                        color: 'auto'
                    }
                },
                splitLine: {    
                    length: 15, 
                    lineStyle: {
                        color: 'auto'
                    }
                },
                axisLabel: {
                    color: '#666',
                },
                detail: {
                formatter:'{value}%',
                fontSize: 24,
                offsetCenter: [0, '85%']
                },
                title: {
                fontSize:16,
                color: '#999',
                offsetCenter: [0, '55%']
                },
                pointer: {
                    width: 5
                }
            }}}}),_c('div',{staticClass:"targrt"},[_c('div',{staticStyle:{"margin-right":"15px"}},[_c('div',[_vm._v("目标")]),_c('div',[_vm._v(_vm._s(_vm.list.achievement_goal))])]),_c('div',[_c('div',[_vm._v("合同金额")]),_c('div',[_vm._v(_vm._s(_vm.list.contract_fee))])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }